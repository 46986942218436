import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/operations.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'
import operationsPage from '../images/operations-pg.svg'

import operations1 from '../images/operations-s1.svg'
import operations2 from '../images/operations-s2.svg'
import operations3 from '../images/operations-s3.svg'
import operations4 from '../images/operations-s4.svg'
import operations5 from '../images/operations-s5.svg'
import operations6 from '../images/operations-s6.svg'

const Operations = ({ data }) => {
	const state = { productName: 'Operations Excellence', planTitle: 'Starter Plan', planPrice: '100 $' }
	const signupClick = () => {
		localStorage.setItem(
			'state',
			JSON.stringify({
				productName: state.productName,
				planTitle: state.planTitle,
				planPrice: state.planPrice,
			})
		)
	}
	return (
		<Fragment>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Layout>
				{/* <SEO
				title="Operations Excellence"
				description="Operations Excellence Products support installments management for your customers"
			/> */}
				<div className="operations-page">
					<div className="Header-parent">
						<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
					</div>
					<div className="page-content operations-content">
						<div className="operations-section">
							<div className="header-content">
								<div className="header-items">
									<h1>Operations Excellence</h1>
									<div className="hint">
										<h3>
											<span>Services Cloud Ready</span>
											<span>
												{' '}
												Platform is the best way to control
												your industry easy and fast, Now
												Drive to the future.
											</span>
										</h3>
									</div>
									<CustomButton className="link-wrapper">
										<Link
											className="demo-request"
											data-track-location="iopsapps"
											to="/pricing"
										>
											Request Demo
										</Link>
									</CustomButton>
								</div>
								<div className="header-imgs">
									<img src={operationsPage} alt="services-left" />
								</div>
							</div>
						</div>
						<section className="products-content" id="products-content">
							<h1 className="product-header">Explore Features</h1>
							<div className="products-container">
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												Installments and collections
												management
											</h3>
											<p className="subtitle">
												Operations Excellence Products
												support installments management for
												your customers, enabling users to
												view their installments historical
												data and their upcoming
												installments.
											</p>
											<CustomButton className="link-wrapper">
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={operations1}
												className="item-img"
												alt="E-Commerce"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Tenants Management
											</h3>
											<p className="subtitle">
												Operations Excellence introduces
												analysis center that will help key
												managers manage & control their
												projects/properties and have a full
												view about their projects.
											</p>
											<CustomButton className="link-wrapper">
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={operations2}
												className="item-img"
												alt="Catalog"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">Rents Management</h3>
											<p className="subtitle">
												Operations Excellence Support Rents
												Management Solutions , you can add
												add multiple tenants to your
												Property , Manage collections ,
												Issue Invoices , Remind your Tenants
												with the upcoming collections.
											</p>
											<CustomButton className="link-wrapper">
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={operations3}
												className="item-img"
												alt="Service"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">Dashboards</h3>
											<p className="subtitle">
												Operations Excellence introduces
												analysis center that will help key
												managers manage & control their
												projects/properties and have a full
												view about their projects.
											</p>
											<CustomButton className="link-wrapper">
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={operations4}
												className="item-img"
												alt="Marketing"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">Landlord</h3>
											<p className="subtitle">
												Landlord features ready to control
												your properties and tenant
												collections scheduling.
											</p>
											<CustomButton className="link-wrapper">
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={operations5}
												className="item-img"
												alt="Personalized"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">Cash Flow</h3>
											<p className="subtitle">
												When you increase the speed and
												accuracy of the bill to pay process,
												you stimulate cash flow. By
												automating the complex task of
												calculating expense participation
												and rent, comprehensive tenant
												statements are prepared and
												delivered faster with greater
												accuracy, resulting in improved cash
												flow.
											</p>
											<CustomButton className="link-wrapper">
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={operations6}
												className="item-img"
												alt="Objects"
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</Fragment>
	)
}
export const pageQuery = graphql`
	query OperationsQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Operations
